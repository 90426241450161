export default class TocScroller {
    constructor(selector, activeClass) {
        this.selector = selector;
        this.activeClass = activeClass;

        if (document.querySelector(this.selector)) {
            this.tocLinks = document.querySelectorAll(`${this.selector} a`);

            this.headers = Array.from(this.tocLinks).map((link) => {
                return document.querySelector(`#${link.href.split('#')[1]}`);
            });

            this.ticking = false;

            window.addEventListener('scroll', (e) => {
                this.onScroll();
            });

            this.onScroll();
        }
    }

    onScroll() {
        if (!this.ticking) {
            requestAnimationFrame(this.update.bind(this));
            this.ticking = true;
        }
    }

    update() {
        this.activeHeader = null;

        let activeIndex = -1;

        for (let i = 0; i < this.headers.length; i++) {
            const header = this.headers[i];
            const isAtHeader = header.getBoundingClientRect().top > 180;

            if (isAtHeader) {
                // Always refer to the previous header
                activeIndex = i - 1;
                break;
            }

            if (!isAtHeader && (i === (this.headers.length - 1))) {
                // Check if we've scrolled past the last header
                activeIndex = this.headers.length - 1;
            }
        }

        const active = this.headers[activeIndex];

        if (active !== this.activeHeader) {
            this.activeHeader = active;

            this.tocLinks.forEach((link) => {
                return link.classList.remove(this.activeClass);
            });

            // It's valid for the active header to be null, when none should be selected
            if (active) {
                this.tocLinks[activeIndex].classList.add(this.activeClass);
            }
        }

        this.ticking = false;
    }
}
