// Generate a random string made with numbers!
export const randomString = (length = 5) => { return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, length); };

// Generate an SVG-based placeholder for Vue templates
export const svgPlaceholder = (width, height) => {
    const svg = `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h${width}v${height}H0z" fill="none"/></svg>`;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
};

export const copyToClipboard = (textToCopy) => {
    // `navigator.clipboard` needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        return navigator.clipboard.writeText(textToCopy);
    }

    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
    });
};
