// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '/scss/style.scss';

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept();
}

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/blur-up/ls.blur-up';

// iFrameResize for Vizy demos
import 'iframe-resizer';

// Nice syntax highlighting for docs
import './prism';

import mixitup from 'mixitup';

import { copyToClipboard } from '@utils/string';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';

// A minimal responsive menu with just vanilla JS
new MobileNav();

// Another mobile nav for plugin sub-menus
new MobileNav({
    openSelector: '[data-mobile-plugin-nav-open]',
    closeSelector: '[data-mobile-plugin-nav-close]',
    activeClass: 'mobile-plugin-nav-open',
});

new ClickToggle({
    openSelector: '[data-craft-v-toggle-open]',
    activeClass: 'craft-v-toggle-open',
    clickAwaySelector: '[data-craft-v-toggle-open]',
});

// Support iFrameResize for Vizy demos
iFrameResize({ }, '#vizy-demo');


// ================================================
// Site Components
// ================================================

import TocScroller from '@components/toc';

// A scrollspy-like behaviour for docs
new TocScroller('.docs-toc', 'toc-active');


// Generic Tabs
document.querySelectorAll('.tab-link').forEach(($element) => {
    $element.addEventListener('click', (e) => {
        e.preventDefault();

        const target = $element.getAttribute('data-tab');
        const $parent = $element.closest('.tabs');

        $parent.querySelectorAll('.tab-link').forEach(($el) => {
            $el.classList.remove('current');
        });

        $parent.querySelectorAll('.tab-pane').forEach(($el) => {
            $el.classList.remove('tab-active');
        });

        $element.classList.add('current');
        $parent.querySelector(`.tab-pane[data-tab="${target}"]`).classList.add('tab-active');
    });
});

// Auto-select the first tab
document.querySelectorAll('.tabs').forEach(($element) => {
    $element.querySelector('.tab-link:first-child').click();
});


// FAQ accordions
document.querySelectorAll('[data-accordion-item]').forEach((el) => {
    el.querySelector('[data-accordion-toggle]').addEventListener('click', (e) => {
        e.preventDefault();

        el.hasAttribute('data-active') ? el.removeAttribute('data-active') : el.setAttribute('data-active', true);
    });
});


// Click-copy anchor links instead of scrolling to them
document.querySelectorAll('.anchor').forEach(($anchor) => {
    $anchor.addEventListener('click', (e) => {
        e.preventDefault();

        let $copy = $anchor.querySelector('span');

        if (!$copy) {
            $copy = document.createElement('span');
        }

        $copy.innerHTML = 'Copied!';

        $anchor.insertBefore($copy, $anchor.querySelector('div'));

        setTimeout(() => {
            $copy.remove();
        }, 2000);

        const url = window.location.href.replace(window.location.hash, '') + $anchor.getAttribute('href');

        copyToClipboard(url);
        window.history.pushState({}, '', url);
    });
});

window.addEventListener('scroll', (e) => {
    const $navbar = document.querySelector('.js-navbar');
    const $subnavbar = document.querySelector('.js-subnavbar');
    const $element = $subnavbar || $navbar;

    if ($element && ($element.classList.contains('sticky') || $element.classList.contains('fixed'))) {
        if (e.currentTarget.scrollY > 100) {
            $element.classList.add('navbar-sticky');
        } else {
            $element.classList.remove('navbar-sticky');
        }
    }
});

window.dispatchEvent(new CustomEvent('scroll'));

// Plugin filter
if (document.querySelector('.js-filter-container')) {
    const mixer = mixitup('.js-filter-container', {
        animation: {
            duration: 500,
            easing: 'ease-in-out',
            animateResizeTargets: false,
            nudge: true,
            reverseOut: false,
            effects: 'fade translateZ(-100px)',
        },
    });

    document.querySelectorAll('.js-filter').forEach(($btn) => {
        $btn.addEventListener('click', (e) => {
            document.querySelectorAll('.js-filter-item').forEach(($item) => {
                $item.classList.remove('active');
            });

            $btn.parentElement.classList.toggle('active');

            // Smooth-scroll to top of container due to sticky filters
            document.querySelector('.js-section-plugins').scrollIntoView({
                behavior: 'smooth',
            });
        });
    });

    const { hash } = document.location;

    if (hash) {
        let filter = hash.replace('#', '.');

        if (filter === '.all-plugins') {
            filter = 'all';
        }

        mixer.filter(filter, false);

        const $historyItem = document.querySelector(`.js-filter[href="${hash}"]`);

        if ($historyItem) {
            $historyItem.click();
        }
    }

    const debounce = function(cb, interval, immediate) {
        let timeout;

        return function() {
            const context = this, args = arguments;

            const later = function() {
                timeout = null;
                if (!immediate) { cb.apply(context, args); }
            };

            const callNow = immediate && !timeout;

            clearTimeout(timeout);
            timeout = setTimeout(later, interval);

            if (callNow) { cb.apply(context, args); }
        };
    };

    document.querySelector('.js-plugin-search').addEventListener('keyup', debounce((e) => {
        const query = e.target.value.toLowerCase().trim();

        if ((query.length) > 0) {
            const matches = [];

            document.querySelectorAll('.mix').forEach(($item) => {
                if ($item.getAttribute('data-keywords').toLowerCase().match(query)) {
                    matches.push($item);
                }

                mixer.filter(matches, false);
            });
        } else {
            mixer.filter('all', false);
        }
    }, 350));
}


// Footer random message
const footerText = [
    'plugins made with ❤️',
    'powered by 🥃',
    'with 💜 from Melbourne, Australia',
    'have you tried turning it 🔴 and 🟢?',
    'never 💩',
    'where\'s the 🔥',
    'add some ✨ to your site',
    'fresh plugins from 🇦🇺',
    'a little bit 🤖',
    '🤘 it since 2015',
    'you\'re amazing 🎉',
    '🥷',
    'hello there 😎',
];

const $footerText = document.querySelector('.js-footer-text');

if ($footerText) {
    $footerText.innerHTML = footerText[Math.floor(Math.random() * footerText.length)];
}
